import React from 'react';
import {
  FormControlLabel,
  FormControl,
  Input,
  InputLabel,
  Typography,
  Checkbox,
  Button,
  FormHelperText,
  CircularProgress
} from '@material-ui/core';
import useForm from '../../hooks/useForm';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const Login = props => {
  const { classes } = props;
  const rules = {
    email: {
      type: 'email',
      required: true
    },
    password: {
      type: 'string',
      required: true,
      minLength: 6
    }
  };

  const login = () => {
    props.login(
      {
        email: values.email,
        password: values.password
      },
      () => {
        props.onLogin();
      }
    );
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    { email: '', password: '' },
    login,
    rules
  );

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="email">Email Address</InputLabel>
        <Input
          id="email"
          name="email"
          autoComplete="email"
          type="email"
          autoFocus
          value={values.email}
          onChange={handleChange}
          error={errors.email || props.error.field === 'email'}
          disabled={props.loading}
        />
        {(props.error.field === 'email' || errors.email) && (
          <FormHelperText error>{props.error.message || errors.email}</FormHelperText>
        )}
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
          name="password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={values.password}
          onChange={handleChange}
          error={errors.password || props.error.field === 'password'}
          disabled={props.loading}
        />
        {(props.error.field === 'password' || errors.password) && (
          <FormHelperText error>{props.error.message || errors.password}</FormHelperText>
        )}
      </FormControl>
      <Typography>
        {/* <Link to={'forgot_password'}>Forgot password?</Link> */}
      </Typography>
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <Button type="submit" fullWidth variant="contained" color="primary" disabled={props.loading}>
        {props.loading && <CircularProgress size={24} className={classes.loader} />}
        {'Sign in'}
      </Button>
    </form>
  );
};

export default withStyles(styles)(Login);
