import { useState, useEffect } from 'react';
import validate from './validationRules';

const useForm = (initValues = {}, callback, rules) => {
  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
      setIsSubmitting(false);
    }
  }, [errors, callback, isSubmitting]);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    setErrors(validate(values, rules));
    setIsSubmitting(true);
  };

  const handleChange = (event, value = false) => {
    //if value passed as second argument
    if (!value) {
      event.persist();
      setValues(values => ({
        ...values,
        [event.target.name]: event.target.value
      }));
    } else {
      setValues(values => ({
        ...values,
        [event]: value
      }));
    }
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors
  };
};

export default useForm;
