import React, { useState } from 'react';
import {
  Card,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  InputAdornment
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ArrowDownward,
  IndeterminateCheckBox,
  CheckBoxOutlineBlank,
  Add,
  Delete,
  SearchOutlined,
  InsertDriveFileOutlined
} from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import TableMenu from './TableMenu';
import csvStringify from 'csv-stringify';
import { NotificationManager } from 'react-notifications';

const styles = theme => ({
  card: {
    height: '100%',
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    margin: theme.spacing.unit * 3
  },
  search: {
    backgroundColor: '#eee',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  search_input: {
    color: '#333'
  },
  search_icon: {
    color: '#bbb'
  }
});

const tableCustomTheme = theme => ({
  rdt_TableHeadRow: {
    cursor: 'pointer'
  }
});

const Table = props => {
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    data,
    title,
    classes,
    create,
    headers,
    defaultSortField,
    addDisabled,
    exportable
  } = props;

  const handleChange = newState => {
    setSelectedRows(newState.selectedRows);
  };

  const handleRowClicked = row => {
    // console.log(`${row.name} was clicked!`);
  };

  const deleteAll = () => {
    // let _ids = selectedRows.reduce((array_of_ids, row) => ([...array_of_ids, row._id]), []);
    // props.deleteMany && props.deleteMany(_ids)
  };

  const deleteOne = row => {
    props.delete(row);
  };

  const editOne = row => {
    props.edit(row);
  };

  const deepSelector = (key, format, value) => {
    if (format) {
      let formatted = format(value);
      if (typeof formatted === 'string') return formatted;
    }
    if (!key) return '';
    let keys = key.split('.');
    for (let i = 0; i < keys.length; i++) {
      if (!value) return;
      const key = keys[i];
      value = value[key];
    }
    return value;
  };

  const exportCsv = () => {
    let csvData = [];
    csvData = data.map(row => headers.map(h => deepSelector(h.selector, h.format, row)));
    csvData.unshift(headers.map(h => h.name));

    csvStringify(csvData, function(err, output) {
      if (err) NotificationManager.error('Failed to export data');
      const csvContent = `data:text/csv;charset=utf-8,${output}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `dataexport${new Date().toLocaleDateString()}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    });
  };

  const actions = [
    <TextField
      key="search"
      InputProps={{
        className: classes.search_input,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" className={classes.search_icon}>
            <SearchOutlined color="inherit" />
          </InputAdornment>
        )
      }}
      onChange={e => props.search && props.search(e.target.value)}
      className={classes.search}
      placeholder="Search..."
    />,
    <Button key="csv" variant="text" color="primary" onClick={exportCsv}>
      <InsertDriveFileOutlined /> Export CSV
    </Button>,
    addDisabled ? null : (
      <Button key="create" variant="text" color="primary" onClick={create}>
        <Add /> Create new
      </Button>
    )
  ];

  const contextActions = [
    <Button key="delete" onClick={deleteAll} style={{ color: 'red' }}>
      <Delete color="secondary" />
      delete
    </Button>
  ];

  const columns = [
    ...headers,
    {
      name: 'Actions',
      cell: row => (
        <TableMenu
          row={row}
          onDeleteRow={props.delete && deleteOne}
          onEditRow={props.edit && editOne}
          actions={props.actions}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '56px'
    }
  ];

  return (
    <Card className={classes.card}>
      <DataTable
        progressPending={props.loading}
        progressComponent={<CircularProgress size={24} className={classes.loader} />}
        title={title}
        columns={columns}
        data={data}
        selectableRows
        highlightOnHover
        defaultSortField={defaultSortField}
        actions={actions}
        contextActions={contextActions}
        sortIcon={<ArrowDownward />}
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          uncheckedicon: isIndeterminate =>
            isIndeterminate ? <IndeterminateCheckBox /> : <CheckBoxOutlineBlank />
        }}
        onTableUpdate={handleChange}
        pagination
      />
    </Card>
  );
};

export default withStyles(styles)(Table);
