export default function validate(values, rules) {
  let errors = {};
  Object.keys(values).forEach(value => {
    const rule = rules[value];
    if (rule && rule.type === 'email') errors[value] = validateEmail(values[value], rule);
    if (rule && rule.type === 'string') errors[value] = validateString(values[value], value, rule);
    if (rule && rule.type === 'number') errors[value] = validateNumber(values[value], value, rule);
    if (rule && rule.type === 'phone')
      errors[value] = validatePhoneNumber(values[value], value, rule);
    if (rule && rule.type === 'checkbox')
      errors[value] = validateCheckbox(values[value], value, rule);
    if (rule && rule.type === 'mac_address')
      errors[value] = validateMacAddress(values[value], value, rule);
  });
  Object.keys(errors).forEach(error => {
    if (!errors[error]) delete errors[error];
  });
  return errors;
}

const validateEmail = (email, rule) => {
  const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegEx.test(email) && rule.required) return 'Enter correct email!';
  else return false;
};

const validateString = (value, name, rules) => {
  if (rules.required && value.length === 0) return `Please enter ${name}`;
  if (rules.minLength && value.length < rules.minLength)
    return `${name} must have at least ${rules.minLength} characters`;
  if (rules.maxLength && value.length > rules.maxLength)
    return `${name} can have at most ${rules.maxLength} characters`;
  else return false;
};

const validateNumber = (value, name, rules) => {
  if (rules.required && (value === undefined || value === null)) return `Please enter ${name}`;
  if (rules.min && value < rules.min)
    return `${name} must have at least ${rules.minLength} characters`;
  if (rules.max && value.length > rules.max)
    return `${name} can have at most ${rules.max} characters`;
  else return false;
};

const validatePhoneNumber = (value, name, rules) => {
  if (rules.required && !value) return `Please enter ${name}`;
  if (rules.min && value.toString().length < rules.min) return `Please enter correct phone number`;
  if (rules.max && value.toString().length > rules.max) return `Please enter correct phone number`;
  else return false;
};

const validateCheckbox = (value, name, rules) => {
  if (rules.required && !value) return `Please enter ${name}`;
};

const validateMacAddress = (value, name, rules) => {
  if (rules.required && !value) return `Please enter Mac Address`;
  const macRegex = /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/;
  if (!macRegex.test(value)) return `Please enter valid Mac Address`;
};
