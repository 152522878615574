import React, { useState } from 'react';
import {
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal
} from '@material-ui/core';
import { Add, ChevronLeft, CodeOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import { drawerWidth } from '../../constants';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../Static/logo-rent24-light.jpg';
import { Link } from 'react-router-dom';

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  logo: {
    height: '64px'
  },
  sidebarbottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }
});

const Sidebar = props => {
  const { classes, drawerOpen, handleDrawerClose } = props;
  const [addPackageModalOpen, setAddPackageModalOpen] = useState(false);
  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })
      }}
      open={drawerOpen}
    >
      <div className={classes.toolbar}>
        <img src={logo} className={classes.logo} alt="24 Solutions" />
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <List>
        {props.routes
          .filter(route => !route.redirect)
          .map(route => (
            <ListItem
              button
              component={Link}
              to={route.path}
              key={route.name}
              selected={route.path === props.location.pathname}
            >
              <ListItemIcon>{<route.icon />}</ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(Sidebar));
