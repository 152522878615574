import React, { useState } from 'react';
import {
  FormControl,
  Input,
  InputLabel,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const ForgotPassword = props => {
  const { classes } = props;
  const [email, setEmail] = useState('');
  const [codeDone, setCodeDone] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [code, setCode] = useState('');

  const handleEmailSubmit = e => {
    e && e.preventDefault();
    props.reset_password_email({ email }, () => {
      setCodeDone(true);
    });
  };

  const handleSubmit = e => {
    e && e.preventDefault();
    props.reset_password({ code, password }, () => {
      props.onResetPassword();
    });
  };

  if (codeDone) {
    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <Typography>Please enter your new password</Typography>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="security_code">Security code</InputLabel>
          <Input
            name="security_code"
            type="text"
            id="security_code"
            value={code}
            autoComplete="off"
            onChange={e => setCode(e.target.value)}
            disabled={props.loading}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="reset_password">New Password</InputLabel>
          <Input
            name="reset_password"
            id="reset_password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={props.loading}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="repeatPassword">Repeat Password</InputLabel>
          <Input
            name="repeatPassword"
            type="password"
            id="repeatPassword"
            value={repeatPassword}
            onChange={e => setRepeatPassword(e.target.value)}
            disabled={props.loading}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="mt-3"
          disabled={props.loading}
        >
          {props.loading && <CircularProgress size={24} className={classes.loader} />}
          {'Reset Password'}
        </Button>
        <Typography className="mt-3">
          <Link to={'login'}>Back to login</Link>
        </Typography>
      </form>
    );
  }

  return (
    <form className={classes.form} onSubmit={handleEmailSubmit}>
      <Typography>Please enter your email address and we will send you a security code</Typography>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="email">Email Address</InputLabel>
        <Input
          id="email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={props.loading}
        />
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="mt-3"
        disabled={props.loading}
      >
        {props.loading && <CircularProgress size={24} className={classes.loader} />}
        {'Send'}
      </Button>
      <Typography className="mt-3">
        <Link to={'login'}>Back to login</Link>
      </Typography>
    </form>
  );
};

export default withStyles(styles)(ForgotPassword);
