import React from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

import useForm from '../../hooks/useForm';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '40%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  },
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  loader: {
    marginRight: theme.spacing.unit
  }
});

const AddCompanyModal = props => {
  const { classes, selectedCompany, loading } = props;

  const id = selectedCompany && selectedCompany._id;

  const submit = () => {
    return id ? props.edit(values) : props.create(values);
  };

  const defaultValues = selectedCompany || {
    name: '',
    email: '',
    phone_number: '',
    country: '',
    address: ''
  };

  const rules = {
    name: {
      type: 'string',
      required: 'true'
    },
    email: {
      type: 'string',
      required: 'true'
    },
    phone_number: {
      type: 'string',
      required: 'true'
    },
    country: {
      type: 'string',
      required: 'true'
    },
    address: {
      type: 'string',
      required: 'true'
    }
  };

  const keyToString = key =>
    key
      .split('_')
      .join(' ')
      .replace(/(?:^|\s)\S/g, function(a) {
        return a.toUpperCase();
      });

  const { values, errors, handleChange, handleSubmit } = useForm(defaultValues, submit, rules);

  return (
    <div className={classes.paper}>
      <Typography variant="h5">{id ? 'Edit Company' : 'Create New Company'}</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        {Object.keys(rules).map(key => (
          <FormControl key={key} margin="normal" required fullWidth>
            <InputLabel htmlFor={key}>{keyToString(key)}</InputLabel>
            <Input
              autoFocus={key === 'name'}
              id={key}
              name={key}
              autoComplete={key}
              value={values[key]}
              onChange={handleChange}
              error={errors[key]}
            />
          </FormControl>
        ))}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          {loading && <CircularProgress size={24} className={classes.loader} />}
          {id ? 'Edit' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default withStyles(styles)(AddCompanyModal);
