import { admin } from '../api';
import { NotificationManager } from 'react-notifications';

const initalState = {
  loading: false,
  error: {}
};

export default function authReducer(state = initalState, action) {
  switch (action.type) {
    case 'LOGIN_PENDING':
      return {
        ...state,
        loading: true
      };
    case 'LOGIN_FULFILLED':
      localStorage.setItem('token', action.payload.data.token);
      action.meta && action.meta();
      return {
        ...state,
        loading: false,
        error: {}
      };
    case 'LOGIN_REJECTED':
      return {
        ...state,
        error: action.payload.response.data,
        loading: false
      };

    default:
      return state;
  }
}

export const login = (body, cb) => ({
  type: 'LOGIN',
  payload: admin.login(body),
  meta: cb
});
