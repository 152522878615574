import React from 'react';
import { login } from '../../reducers/authReducer';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { CssBaseline, Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from '../../Static/logo-rent24-light.jpg';

import Login from './Login';
import ForgotPassword from './ForgotPassword';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  logo: {
    width: 200
  }
});

function PreAuth(props) {
  const { classes, error, loading, login } = props;

  const onLogin = () => props.history.push('/dashboard');
  const onResetPassword = () => props.history.push('/login');

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img src={logo} className={classes.logo} alt="24 Solutions" />
        <Switch>
          <Redirect exact path="/" to="/login" />
          <Route
            path="/login"
            render={() => <Login error={error} loading={loading} login={login} onLogin={onLogin} />}
          />
        </Switch>
      </Paper>
    </main>
  );
}

function mapState(state) {
  return {
    loading: state.authReducer.loading,
    error: state.authReducer.error
  };
}

function mapDispatch(dispatch) {
  return bindActionCreators({ login }, dispatch);
}

export default withRouter(
  withStyles(styles)(
    connect(
      mapState,
      mapDispatch
    )(PreAuth)
  )
);
