import React, { Fragment } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
const Confirmation = props => {
  const { title, description, actions } = props;
  return (
    <Fragment>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map(action => (
          <Button key={action.text} onClick={action.onClick} color={action.color}>
            {action.text}
          </Button>
        ))}
      </DialogActions>
    </Fragment>
  );
};

export default Confirmation;
