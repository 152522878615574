import React, { useState, useEffect } from 'react';
import { Modal, Dialog, Chip } from '@material-ui/core';
import { SecurityOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import AddCompanyModal from './AddCompanyModal';
import Table from '../../components/Table/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { create, list, edit_access } from '../../reducers/companyReducer';
import moment from 'moment';
import Confirmation from '../../components/Confirmation/Confirmation';
import debounce from '../../util/debounce';

const styles = theme => ({});

const Companies = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [editAccessDialogOpen, setEditAccessDialogOpen] = useState(false);

  const { companies, did_get, loading } = props;

  useEffect(() => {
    setTimeout(() => {
      props.list();
    }, 1);
  }, []);

  const create = company => {
    props.create(company, () => {
      setModalOpen(false);
    });
  };

  const onCreate = () => {
    setSelectedCompany(null);
    setModalOpen(true);
  };

  const onEditAccess = company => {
    setSelectedCompany(company);
    setEditAccessDialogOpen(true);
  };

  const editAccess = hasAccess => {
    props.edit_access({ hasAccess }, selectedCompany._id, () => {
      setEditAccessDialogOpen(false);
    });
  };

  const headers = [
    {
      name: 'Company Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Customer Since',
      selector: 'createdAt',
      sortable: true,
      format: ({ createdAt }) => moment(createdAt).format('DD.MM.YYYY')
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true
    },
    {
      name: 'Phone number',
      selector: 'phone_number',
      sortable: true
    },
    {
      name: 'Country',
      selector: 'country',
      sortable: true
    },
    {
      name: 'Address',
      selector: 'address',
      sortable: true
    },
    {
      name: 'Access',
      selector: 'hasAccess',
      sortable: true,
      format: ({ hasAccess }) => (
        <Chip label={hasAccess ? 'Yes' : 'No'} color={hasAccess ? 'primary' : 'secondary'} />
      )
    }
  ];

  const search = keyword => {
    debounce(500, () => {
      props.list({ email: keyword, full_name: keyword });
    });
  };

  const actions = [
    {
      text: 'Edit Access',
      onClick: onEditAccess,
      icon: SecurityOutlined,
      key: 'editaccess'
    }
  ];

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <AddCompanyModal loading={loading} selectedCompany={selectedCompany} create={create} />
      </Modal>
      <Dialog open={editAccessDialogOpen} onClose={() => setEditAccessDialogOpen(false)}>
        <Confirmation
          title="Are you sure you want to edit access for this company?"
          description="This will modify access to admin panel and and all other functionality"
          actions={[
            {
              text: 'Deny Access',
              color: 'secondary',
              onClick: () => editAccess(false)
            },
            {
              text: 'Grant Access',
              color: 'primary',
              onClick: () => editAccess(true)
            },
            {
              text: 'Cancel',
              color: 'default',
              onClick: () => setEditAccessDialogOpen(false)
            }
          ]}
          disagreeText="Cancel"
          disagreeAction={() => setEditAccessDialogOpen(false)}
          agreeText="EditAccess Permanently"
          agreeAction={() => {}}
        />
      </Dialog>
      <Table
        search={search}
        loading={loading}
        data={companies}
        headers={headers}
        create={onCreate}
        actions={actions}
        title="Companies"
        defaultSortField="first_name"
      />
    </div>
  );
};

const mapState = state => ({
  loading: state.companyReducer.loading,
  companies: state.companyReducer.companies,
  did_get: state.companyReducer.did_get
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      create,
      list,
      edit_access
    },
    dispatch
  );

export default withStyles(styles)(
  connect(
    mapState,
    mapDispatch
  )(Companies)
);
