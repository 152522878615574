import { company } from '../api';
import { NotificationManager } from 'react-notifications';

const initialState = {
  company: {},
  companies: [],
  loading: false,
  error: undefined,
  did_get: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_COMPANY_PENDING':
      return { ...state, loading: true };
    case 'CREATE_COMPANY_FULFILLED':
      NotificationManager.success('Successfully Created User');
      action.meta && action.meta();
      return { ...state, companies: [action.payload.data, ...state.companies], loading: false };
    case 'CREATE_COMPANY_REJECTED':
      NotificationManager.error('User Already Exists');
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case 'LIST_COMPANIES_PENDING':
      return { ...state, loading: true };
    case 'LIST_COMPANIES_FULFILLED':
      return { ...state, companies: action.payload.data, did_get: true, loading: false };
    case 'LIST_COMPANIES_REJECTED':
      NotificationManager.error('Network error');
      return { ...state, loading: false };
    case 'EDIT_ACCESS_PENDING':
      return { ...state, loading: true };
    case 'EDIT_ACCESS_FULFILLED':
      const updatedCompany = action.payload.data;
      const companies = [...state.companies];
      const index = companies.findIndex(c => c._id === updatedCompany._id);
      companies[index] = updatedCompany;
      action.meta && action.meta();
      return {
        ...state,
        companies,
        did_get: true,
        loading: false
      };
    case 'EDIT_ACCESS_REJECTED':
      NotificationManager.error('Network error');
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const create = (body, cb) => ({
  type: 'CREATE_COMPANY',
  payload: company.create(body),
  meta: cb
});

export const list = (body, cb) => ({
  type: 'LIST_COMPANIES',
  payload: company.list(body),
  meta: cb
});

export const edit_access = (body, id, cb) => ({
  type: 'EDIT_ACCESS',
  payload: company.edit_access(body, id),
  meta: cb
});
