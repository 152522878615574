export const drawerWidth = 240;

export const hours = [
  { value: 0, label: '00:00' },
  { value: 30, label: '00:30' },
  { value: 60, label: '01:00' },
  { value: 90, label: '01:30' },
  { value: 120, label: '02:00' },
  { value: 150, label: '02:30' },
  { value: 180, label: '03:00' },
  { value: 210, label: '03:30' },
  { value: 240, label: '04:00' },
  { value: 270, label: '04:30' },
  { value: 300, label: '05:00' },
  { value: 330, label: '05:30' },
  { value: 360, label: '06:00' },
  { value: 390, label: '06:30' },
  { value: 420, label: '07:00' },
  { value: 450, label: '07:30' },
  { value: 480, label: '08:00' },
  { value: 510, label: '08:30' },
  { value: 540, label: '09:00' },
  { value: 570, label: '09:30' },
  { value: 600, label: '10:00' },
  { value: 630, label: '10:30' },
  { value: 660, label: '11:00' },
  { value: 690, label: '11:30' },
  { value: 720, label: '12:00' },
  { value: 750, label: '12:30' },
  { value: 780, label: '13:00' },
  { value: 810, label: '13:30' },
  { value: 840, label: '14:00' },
  { value: 870, label: '14:30' },
  { value: 900, label: '15:00' },
  { value: 930, label: '15:30' },
  { value: 960, label: '16:00' },
  { value: 990, label: '16:30' },
  { value: 1020, label: '17:00' },
  { value: 1050, label: '17:30' },
  { value: 1080, label: '18:00' },
  { value: 1110, label: '18:30' },
  { value: 1140, label: '19:00' },
  { value: 1170, label: '19:30' },
  { value: 1200, label: '20:00' },
  { value: 1230, label: '20:30' },
  { value: 1260, label: '21:00' },
  { value: 1290, label: '21:30' },
  { value: 1320, label: '22:00' },
  { value: 1350, label: '22:30' },
  { value: 1380, label: '23:00' },
  { value: 1410, label: '23:30' }
];
export const days = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 0, label: 'Sunday' }
];
