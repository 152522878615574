import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';

import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

export default function configureStore(initialState = {}) {
  return createStore(rootReducer, initialState, applyMiddleware(promiseMiddleware, thunk));
}
