import axios from 'axios';
import { BASE_URL } from './config';

const defaultHeaders = () => ({
  Authorization: 'Bearer ' + localStorage.getItem('token')
});

export const get = (url, params = {}, headers = defaultHeaders()) =>
  axios
    .get(BASE_URL + url, { params, headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const post = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .post(BASE_URL + url, body, { headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const put = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .put(BASE_URL + url, body, { headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const patch = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .patch(BASE_URL + url, body, { headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const del = (url, body = {}, headers = defaultHeaders()) =>
  axios
    .delete(BASE_URL + url, { body, headers })
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      throw error;
    });

export const admin = {
  login: body => post('admin/login', body)
  // reset_password_email: body => post('admin/reset_password_email', body),
  // reset_password: body => post('admin/reset_password', body)
};

export const company = {
  list: body => get('company', body),
  create: body => post('company', body),
  edit_access: (body, id) => patch(`company/${id}/access`, body)
  // edit: (body, id) => patch(`company/${id}`, body),
  // delete: (body, id) => del(`company/${id}`, body)
};

export const pass = {};
