import {
  PeopleOutline
} from '@material-ui/icons';

const Routes = [
  {
    path: '/dashboard',
    name: 'Companies',
    icon: PeopleOutline
  },
  { redirect: true, path: '/', pathTo: '/dashboard', name: 'Companies' }
];
export default Routes;
